import React, { useState } from 'react'
import { Button } from 'antd'
import './index.less'

const IconFont = Loader.loadBaseComponent('IconFont')
const TreeSelectCamera = Loader.loadBaseComponent('TreeSelectCamera')
const MapSelectDevices = Loader.loadModuleComponent('MapComponent', 'SelectMap')

function TaskSelectCameras({ value, defaultValue, points, onChange }) {
  const [type, setType] = useState(1)
  const [cids, setCids] = useState(value || defaultValue || [])

  const onChangeSelect = function(list) {
    const ids = list.map(item => item.cid)
    setCids(ids)
    onChange && onChange(ids)
  }

  return (
    <div className="task-select-device">
      <div className="device-select-btn">
        <Button className={`btn ${type === 1 && 'active'}`} onClick={() => setType(1)}>
          <IconFont type="icon-S_Photo_ListMap" />
          地图模式
        </Button>
        <Button className={`btn ${type === 2 && 'active'}`} onClick={() => setType(2)}>
          <IconFont type="icon-S_Photo_ListTree" />
          列表模式
        </Button>
      </div>
      {type === 1 ? (
        <div className="libs-select-map">
          <MapSelectDevices points={points} selectList={cids} onChange={result => onChangeSelect(result.list)} noAutoUpdate={true} />
        </div>
      ) : (
        <div className="libs-select-list">
          <TreeSelectCamera onChange={onChangeSelect} selectList={cids} noSoldier={true} />
        </div>
      )}
    </div>
  )
}

export default TaskSelectCameras
