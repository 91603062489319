(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"));
	else if(typeof define === 'function' && define.amd)
		define("TaskSelectCameras", ["React", "antd"], factory);
	else if(typeof exports === 'object')
		exports["TaskSelectCameras"] = factory(require("React"), require("antd"));
	else
		root["TaskSelectCameras"] = factory(root["React"], root["antd"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 